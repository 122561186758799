import { useSession } from "next-auth/react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  GridMKTValidation,
  MKTValidationInterface,
  MKTValidationResponse,
  RejectedOptionsId,
  RowMKTValidation,
} from "@/interfaces";
import { dataServices } from "./dataServices";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { api_v1 } from "@/shared/api";
import { CampaignsOptions } from "@/constants/campaignsData";
import { serialize } from "object-to-formdata";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";

export function transform2GridMKTValidation(
  arr: RowMKTValidation
): GridMKTValidation {
  const result: any[][] = [];
  const chunkSize = 3;
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

function getDataToMKTValidation(arr: any) {
  return arr.flatMap(
    (dataTest: MKTValidationResponse): MKTValidationInterface => {
      return {
        id_validation: dataTest.id ?? "",
        name_activity: dataTest.activity.data.name ?? "",
        name_badge: dataTest.activity.data.badge.data.name ?? "",
        description: dataTest.activity.data.description ?? "",
        evidence: dataTest.image ?? "",
        user_name:
          dataTest.recycler.data.first_name && dataTest.recycler.data.last_name
            ? `${dataTest.recycler.data.first_name} ${dataTest.recycler.data.last_name}`
            : undefined,
        id_user: dataTest.recycler.data.id ?? "",
        date_insert: new Date(dataTest.created_at).toLocaleDateString("es-MX"),
      };
    }
  );
}

export const useDataMKTValidation = (
  per_page: number,
  type_validations: number,
  badge?: string
) => {
  const { data } = useSession();
  const { control, watch } = useForm<{
    globalRange: DateRange;
    search: string;
    place_shopping_id: string;
    month: string;
    year: string;
  }>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      search: "",
      place_shopping_id: "",
      month: "",
      year: "",
    },
  });
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    pageSize,
    onChangePageSize,
  } = usePagination(per_page);

  const { data: requestData, refetch: refetchData } = useQuery({
    queryKey: [
      "validations_mkt_images_activity",
      `${currentPage}_${pageSize}`,
      `${type_validations}_${badge}`,
    ],
    enabled: data?.user?.token ? true : false,
    structuralSharing: false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/recycler_activity_media",
            params: {
              per_page: pageSize,
              page: currentPage,
              include_recycler: true,
              "include_activity.badge": true,
              admin: true,
              // month: month,
              // year: year,
              status:
                type_validations == 0
                  ? "pending"
                  : type_validations == 1
                  ? "approved"
                  : "rejected",
              badge_id: badge === "null" ? "" : badge,
            },
            token: data?.user?.token ?? undefined,
          })
        : null;
      const data_MKTValidation: RowMKTValidation = getDataToMKTValidation(
        results.data
      );
      toast({
        title: "Solicitando la información",
        description: `Espere un momento en lo que se carga la infromación solicitada.`,
        variant: "warning",
      });
      return { data: data_MKTValidation, results, meta: { ...results.meta } };
    },
  });

  const { data: totals, refetch: refetchTotals } = useQuery({
    queryKey: ["totals_ticket_grid"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/recycler_activity_media/totals",
            token: data?.user?.token || "",
          })
        : null;
      return results;
    },
  });

  useEffect(() => {
    if (requestData?.data) {
      setTotalPages(requestData?.meta.total_pages);
      setTotal(requestData?.meta.total_count);
    }
  }, [requestData?.meta]);

  function forceRefetchMKTValidations() {
    // setCurrentPage(1);
    toast({
      title: "Solicitando la información",
      description: `Espere un momento en lo que se carga la infromación solicitada.`,
      variant: "warning",
    });
    refetchData();
    refetchTotals();
  }

  useEffect(() => {
    forceRefetchMKTValidations();
  }, [
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("search"),
    watch("place_shopping_id"),
    type_validations,
    pageSize,
  ]);

  async function onSubmitValidMKTValidation(
    dataValidation: MKTValidationInterface
  ) {
    const object_to_update = {
      recycler_activity_medium: {
        recycler_activity_medium_status: "approved",
        comment: dataValidation.comments,
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/recycler_activity_media/${dataValidation.id_validation}`,
      requestType: "PATCH",
      body: formData,
      contentType: "multipart/form-data",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchMKTValidations();
  }

  async function onSubmitRejectedMKTValidation(
    dataValidation: MKTValidationInterface
  ) {
    const object_to_update = {
      recycler_activity_medium: {
        recycler_activity_medium_status: "rejected",
        comment: dataValidation.comments,
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/recycler_activity_media/${dataValidation.id_validation}`,
      requestType: "PATCH",
      body: formData,
      contentType: "multipart/form-data",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchMKTValidations();
  }

  async function onSubmitDeleteMKTValidation(
    dataValidation: MKTValidationInterface
  ) {
    const resp = dataServices({
      url: `/recycler_activity_media/${dataValidation.id_validation}`,
      requestType: "DELETE",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchMKTValidations();
  }

  return {
    totals,
    requestData,
    control_date: control,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
    refetch: forceRefetchMKTValidations,
    onSubmitValidMKTValidation,
    onSubmitRejectedMKTValidation,
    onSubmitDeleteMKTValidation,
  };
};
